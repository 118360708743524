<template>
  <!-- 
    画面: 掲載サイト新規登録
    用途: 掲載サイトを新規登録する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("siteCreatePage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">
              <!-- #region 入力フィールド -->

              <!-- #region コード -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("siteCreatePage.code")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.code" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.code.$dirty && !$v.edit.code.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("siteCreatePage.code"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion コード -->

              <!-- #region 略称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("siteCreatePage.shortName")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.shortName" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.shortName.$dirty && !$v.edit.shortName.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("siteCreatePage.shortName"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 略称 -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("siteCreatePage.name")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("siteCreatePage.name"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region URL -->
              <CRow>
                <CCol class="label"><strong>{{
                    $t("siteCreatePage.url")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.url" />
                </CCol>
              </CRow>
              <!-- #endregion URL -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <!-- #region Modal -->
    <template>

      <!-- #region Check -->
      <CModal
        color="info"
        size="lg"
        :closeOnBackdrop="false"
        :centered="true"
        :show.sync="checkModal"
      >
        <CForm class="submit_form">

          <!-- #region コード -->
          <CRow>
            <CCol class="label"><strong>{{ $t("siteCreatePage.code") }}</strong></CCol>
            <CCol><span> {{ edit.code }} </span></CCol>
          </CRow>
          <!-- #endregion コード -->

          <!-- #region 略称 -->
          <CRow>
            <CCol class="label"><strong>{{ $t("siteCreatePage.shortName") }}</strong></CCol>
            <CCol><span> {{ edit.shortName }} </span></CCol>
          </CRow>
          <!-- #endregion 略称 -->

          <!-- #region 名称 -->
          <CRow>
            <CCol class="label"><strong>{{ $t("siteCreatePage.name") }}</strong></CCol>
            <CCol><span> {{ edit.name }} </span></CCol>
          </CRow>
          <!-- #endregion 名称 -->

          <!-- #region URL -->
          <CRow>
            <CCol class="label"><strong>{{ $t("siteCreatePage.url") }}</strong></CCol>
            <CCol><span> {{ edit.url }} </span></CCol>
          </CRow>
          <!-- #endregion URL -->

        </CForm>
        <template #header> {{ $t("common.check") }} </template>
        <template #footer>
          <CButton
            @click="checkModal = false"
            color="secondary"
          >
            <i class="icon cil-x"></i> {{ $t("common.cancel") }}
          </CButton>
          <CButton
            color="success"
            @click="onRegisterClicked()"
          >
            <i class="icon cil-save mr-1"></i> {{
          $t("common.register")
        }}
          </CButton>
        </template>
      </CModal>
      <!-- #endregion Check -->

      <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />

      <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />

    </template>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";

import {
  required,
  // numeric,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "SiteCreate",

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    }
  },

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      checkModal: false,
      //#endregion Flag


      //#region Edit
      edit: {},
      //#endregion Edit


      //#region Error message
      errorMessage: "",
      //#endregion Error message
    };
  },

  validations() {
    return {
      edit: {
        code: { required },
        name: { required },
        shortName: { required },
        url: {},
      },
    };
  },

  methods: {
    //#region Event
    onCheckClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : (this.checkModal = true);
    },

    onRegisterClicked() {
      this.reqPost();
    },

    onBackClicked() {
      this.backPage();
    },
    //#endregion Event


    //#region Request
    reqPost() {
      const body = this.edit;
      const callback = () => this.successModal = true;
      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };
      
      this.reqPostSite(body, callback, errorCallback);
    },
    //#endregion Request


    //#region Method
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },
};
</script>
